<div class="c-top-bg" style="background-image: url('./assets/base/img/content/shop/euless2020-1.jpg');"></div>
<div class="c-content-box c-size-md c-bg-grey">
	<div class="container">
		<div class="row c-manicures-list"></div>
	</div>
</div>

<div class="c-content-box c-size-xs pb-5 c-bg-grey">
	<div class="container">
		<owl-carousel-o [options]="customOptions">
		    <ng-container *ngFor="let slide of slides">
		      <ng-template carouselSlide>
		        <img src="./assets/base/img/content/shop/{{ slide.img }}.jpg" alt="" />
		      </ng-template>
		    </ng-container>
		</owl-carousel-o>
	</div>
</div>