<!-- BEGIN: LAYOUT/SLIDERS/REVO-SLIDER-4 -->
<section class="c-layout-revo-slider c-layout-revo-slider-4" dir="ltr">
    <div class="tp-banner-container c-theme">
        <div class="tp-banner rev_slider" data-version="5.0">
            <ul>
                <!--BEGIN: SLIDE #1 -->
                <li data-transition="fade" data-slotamount="1" data-masterspeed="1000">
                    <img 
                        alt="" 
                        src="../../assets/base/img/content/backgrounds/bg-13.jpg"
                        data-bgposition="center center" 
                        data-bgfit="cover" 
                        data-bgrepeat="no-repeat"
                        >   
                    <div class="tp-caption customin customout"
                        data-x="center" 
                        data-y="center"
                        data-hoffset="" 
                        data-voffset="-50"
                        data-speed="500" 
                        data-start="1000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-splitin="none" 
                        data-splitout="none" 
                        data-elementdelay="0.1" 
                        data-endelementdelay="0.1" 
                        data-endspeed="600">
                        <h3 class="c-main-title-circle c-font-48 c-font-bold c-font-center c-font-uppercase c-font-white c-block">
                            Our Services
                        </h3>
                    </div>
                    <div class="tp-caption lft"
                        data-x="center"
                        data-y="center" 
                        data-voffset="110"
                        data-speed="900" 
                        data-start="2000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" >
                        <a routerLink = "/services" class="c-action-btn btn btn-md c-btn-square c-theme-btn c-btn-bold c-btn-uppercase">Learn More</a>
                    </div>
                </li>
                <!--END -->  

                <!--BEGIN: SLIDE #2 -->
                <li data-transition="fade" data-slotamount="1" data-masterspeed="1000">
                    <img 
                        alt="" 
                        src="../../assets/base/img/content/backgrounds/bg-15.jpg"
                        data-bgposition="center center" 
                        data-bgfit="cover" 
                        data-bgrepeat="no-repeat"
                        >   
                    <div class="tp-caption customin customout"
                        data-x="center" 
                        data-y="center"
                        data-hoffset="" 
                        data-voffset="-50"
                        data-speed="500" 
                        data-start="1000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:600;e:Back.easeInOut;" 
                        data-splitin="none" 
                        data-splitout="none" 
                        data-elementdelay="0.1" 
                        data-endelementdelay="0.1" 
                        data-endspeed="600">
                        <h3 class="c-main-title-circle c-font-48 c-font-bold c-font-center c-font-uppercase c-font-white c-block">
                            Pedicure
                        </h3>
                    </div>
                    <div class="tp-caption lft"
                        data-x="center"
                        data-y="center" 
                        data-voffset="110"
                        data-speed="900" 
                        data-start="2000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:900;e:Back.easeInOut;" 
                        data-transform_out="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:900;e:Back.easeInOut;">
                        <a routerLink = "/pedicure" class="c-action-btn btn btn-md c-btn-square c-theme-btn c-btn-bold c-btn-uppercase">Learn More</a>
                    </div>
                </li>
                <!--END --> 

                <!--BEGIN: SLIDE #3 -->
                <li data-transition="fade" data-slotamount="1" data-masterspeed="1000">
                    <img 
                        alt="" 
                        src="../../assets/base/img/content/backgrounds/bg-16.jpg"
                        data-bgposition="center center" 
                        data-bgfit="cover" 
                        data-bgrepeat="no-repeat"
                        >   
                    <div class="tp-caption customin customout"
                        data-x="center" 
                        data-y="center"
                        data-hoffset="" 
                        data-voffset="-50"
                        data-speed="500" 
                        data-start="1000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-splitin="none" 
                        data-splitout="none" 
                        data-elementdelay="0.1" 
                        data-endelementdelay="0.1" 
                        data-endspeed="600">
                        <h3 class="c-main-title-circle c-font-48 c-font-bold c-font-center c-font-uppercase c-font-white c-block">
                            Manicure
                        </h3>
                    </div>
                    <div class="tp-caption lft"
                        data-x="center"
                        data-y="center" 
                        data-voffset="110"
                        data-speed="900" 
                        data-start="2000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="x:100;y:100;rX:120;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" >
                        <a routerLink = "/manicure" class="c-action-btn btn btn-md c-btn-square c-theme-btn c-btn-bold c-btn-uppercase">Learn More</a>
                    </div>
                </li>
                <!--END -->   

                <!--BEGIN: SLIDE #4 -->
                <li data-transition="fade" data-slotamount="1" data-masterspeed="700" data-delay="6000" data-thumb="">
                    <!-- THE MAIN IMAGE IN THE FIRST SLIDE -->
                    <img 
                        alt="" 
                        src="../../assets/base/img/content/backgrounds/bg-14.jpg"
                        data-bgposition="center center" 
                        data-bgfit="cover" 
                        data-bgrepeat="no-repeat"
                        class="visible-xs"
                        />  

                    <div class="tp-caption customin customout"
                        data-x="center" 
                        data-y="center"
                        data-hoffset="" 
                        data-voffset="-30"
                        data-speed="500" 
                        data-start="1000" 
                        data-transform_idle="o:1;" 
                        data-transform_in="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:500;e:Back.easeInOut;" 
                        data-transform_out="rX:0.5;scaleX:0.75;scaleY:0.75;o:0;s:600;e:Back.easeInOut;" 
                        data-splitin="none" 
                        data-splitout="none" 
                        data-elementdelay="0.1" 
                        data-endelementdelay="0.1" 
                        data-endspeed="600">
                        <h3 class="c-main-title-square c-font-55 c-font-bold c-font-center c-font-uppercase c-font-white c-block">
                            Let us show you<br>Unlimited possibilities
                        </h3>
                    </div>
                </li>
                <!--END -->

            </ul>
        </div>
    </div>
</section><!-- END: LAYOUT/SLIDERS/REVO-SLIDER-4 -->

<!-- BEGIN: CONTENT/MISC/ABOUT-1 -->
<div class="c-content-box c-size-md c-bg-white c-intro">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 wow animated fadeInLeft">						
				<!-- Begin: Title 1 component -->
				<div class="c-content-title-1">
					<h3 class="c-font-bold">Welcome to Grand Nail Spa</h3>
					<div class="c-line-left c-theme-bg"></div>
					<p>Come see for yourself why Grand Nail Spa was voted best of Services.</p>
				</div>
				<!-- End-->
				<p> Grand Nail Spa is located in North West Dallas.  It’s the perfect place to enjoy a luxurious manicure and pedicure in a relaxing atmosphere without paying resort prices.  Grand Nail Spa is always up-to-date with the latest technology and trends in the nail industry. Feel secure knowing that your salon is free from bacteria and has the highest sanitation standards.  We’re also featuring Shellac and Gelish semi-permanent manicures.  All services are provided by highly trained, attentive nail professionals.  We hope you enjoy Grand Nail Spa as much as we do!</p>
			</div>
		</div>
	</div> 
</div><!-- END: CONTENT/MISC/ABOUT-1 -->

<!-- BEGIN: CONTENT/TILES/TILE-3 -->
<div class="c-content-box c-size-sm c-bg-white c-tile-services">
	<div class="c-content-tile-grid c-bs-grid-reset-space" data-auto-height="true">
		<div class="row wow animate fadeInUp">
			<div class="col-md-6">
				<div class="c-content-tile-1 c-bg-green">
					<div class="row">
						<div class="col-sm-6">
							<div class="c-tile-content c-content-v-center" data-height="height">
								<div class="c-wrapper">
									<div class="c-body c-center">
										<h3 class="c-tile-title c-font-25 c-line-height-34 c-font-uppercase c-font-bold c-font-white">
											Services
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="c-tile-content c-arrow-right c-arrow-green c-content-overlay">
								<div class="c-image c-overlay-object" data-height="height" style="background-image: url(../../assets/base/img/content/services/05.jpg)"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="c-content-tile-1 c-bg-brown-2">
					<div class="row">
						<div class="col-sm-6">
							<div class="c-tile-content c-content-v-center" data-height="height">
								<div class="c-wrapper">
									<div class="c-body c-center">
										<h3 class="c-tile-title c-font-25 c-line-height-34 c-font-uppercase c-font-bold c-font-white">
											Relaxation
										</h3>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="c-tile-content c-arrow-right c-arrow-brown-2 c-content-overlay">
								<div class="c-image c-overlay-object" data-height="height" style="background-image: url(../../assets/base/img/content/services/01.jpg)"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="c-content-tile-1 c-bg-red-2">
					<div class="row">
						<div class="col-sm-6">
							<div class="c-tile-content c-arrow-left c-arrow-red-2 c-content-overlay">
								<div class="c-image c-overlay-object" data-height="height" style="background-image: url(../../assets/base/img/content/services/02.jpg)"></div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="c-tile-content c-content-v-center" data-height="height">
								<div class="c-wrapper">
									<div class="c-body c-center">
										<h3 class="c-tile-title c-font-25 c-line-height-34 c-font-uppercase c-font-bold c-font-white">
											Spa
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="c-content-tile-1 c-bg-blue-3">
					<div class="row">
						<div class="col-sm-6">
							<div class="c-tile-content c-arrow-left c-arrow-blue-3 c-content-overlay">
								<div class="c-image c-overlay-object" data-height="height" style="background-image: url(../../assets/base/img/content/services/03.jpg)"></div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="c-tile-content c-content-v-center" data-height="height">
								<div class="c-wrapper">
									<div class="c-body c-center">
										<h3 class="c-tile-title c-font-25 c-line-height-34 c-font-uppercase c-font-bold c-font-white">
											Refresh
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- END: CONTENT/TILES/TILE-3 -->

<!-- BEGIN: CONTENT/TABS/TAB-1 -->
<div class="c-content-box c-size-md c-no-bottom-padding c-overflow-hide c-bg-white c-tab-services">
	<div class="c-container">
		<div class="row">
			<div class="col-md-12">
				<div class="c-content-title-1">
					<h3 class="c-font-34 c-font-center c-font-bold c-font-uppercase c-margin-b-30">
						Our Services
					</h3>
					<div class="c-line-center c-theme-bg"></div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<div class="c-content-tab-2 c-theme c-opt-1">
					<ul class="nav c-tab-icon-stack c-font-sbold c-font-uppercase">
						<li class="active">
							<a href="#c-tab2-opt1-1" data-toggle="tab">
								<span class="c-title">Skin</span>
							</a>
							<div class="c-arrow"></div>
						</li>
						<li>
							<a href="#c-tab2-opt1-2" data-toggle="tab">
								<span class="c-title">Nails</span>
							</a>
							<div class="c-arrow"></div>
						</li>
						<li>
							<a href="#c-tab2-opt1-3" data-toggle="tab">
								<span class="c-title">Facials</span>
							</a>
							<div class="c-arrow"></div>
						</li>
					</ul>
					<div class="c-tab-content">
						<div class="c-bg-img-center1">
							<div class="container">
								<div class="tab-content">
									<div class="tab-pane fade in active" id="c-tab2-opt1-1">
										<div class="c-tab-pane">
											<img class="img-responsive" src="../../assets/base/img/content/services/103.jpg" alt=""/>

											<h4 class="c-font-30 c-font-thin c-font-uppercase c-font-bold">
												Refresh, Renew and Recharge Your Body and Spirit
											</h4>

											<p class="c-font-17 c-margin-b-20 c-margin-t-20 c-font-thin ">
												At Grand Nail Spa will make it easy and affordable to incorporate wellness into your lifestyle. To look and feel your best, start with our licensed experts. They will provide you with professional treatments designed to deliver real, visible results and prescribe an at-home regimen that will ensure your optimal health.
											</p>
										</div>
									</div>
									<div class="tab-pane fade" id="c-tab2-opt1-2">
										<div class="c-tab-pane">
											<img class="img-responsive" src="../../assets/base/img/content/services/102.jpg" alt=""/>

											<h4 class="c-font-30 c-font-thin c-font-uppercase c-font-bold">
												Refresh, Renew and Recharge Your Body and Spirit
											</h4>

											<p class="c-font-17 c-margin-b-20 c-margin-t-20 c-font-thin ">
												At Grand Nail Spa will make it easy and affordable to incorporate wellness into your lifestyle. To look and feel your best, start with our licensed experts. They will provide you with professional treatments designed to deliver real, visible results and prescribe an at-home regimen that will ensure your optimal health.
											</p>
										</div>
									</div>
									<div class="tab-pane fade" id="c-tab2-opt1-3">
										<div class="c-tab-pane">
											<img class="img-responsive" src="../../assets/base/img/content/services/105.jpg" alt=""/>

											<h4 class="c-font-30 c-font-thin c-font-uppercase c-font-bold">
												Refresh, Renew and Recharge Your Body and Spirit
											</h4>

											<p class="c-font-17 c-margin-b-20 c-margin-t-20 c-font-thin ">
												At Grand Nail Spa will make it easy and affordable to incorporate wellness into your lifestyle. To look and feel your best, start with our licensed experts. They will provide you with professional treatments designed to deliver real, visible results and prescribe an at-home regimen that will ensure your optimal health.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</div><!-- END: CONTENT/TABS/TAB-1 -->