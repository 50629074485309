import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';

import { ServicesService } from '../_services/services.service';

@Component({
  selector: 'app-manicure',
  templateUrl: './manicure.component.html',
  styleUrls: ['./manicure.component.scss']
})
export class ManicureComponent implements OnInit {
	manicures = this.services['manicure'];

	slides = [
		{img: "euless0"},
		{img: "euless1"},
		{img: "euless2"},
		{img: "euless4"},
		{img: "euless5"},
		{img: "euless6"},
		{img: "euless7"},
	];
	customOptions: OwlOptions = {
	    loop: true,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    autoplaySpeed: 500,
	    autoplay: true,
	    navText: ['<', '>'],
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 2
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	    nav: true
	}
	
  	constructor(
  		private services: ServicesService
  	) { 
  		if ($('.c-mega-menu').hasClass('c-shown')){
            $('.c-mega-menu').removeClass('c-shown');
            $('body').removeClass('c-mega-menu-shown');
        }
    }

  	ngOnInit(): void {
  		this.renderManicures(this.manicures);
  	}

  	renderManicures(items){
  		var html = '';
  		for (var i = 0; i < items.length; ++i) {
  			var descArr = items[i]['desc'].split('\n');
  			var desc = '';
  			for (var j = 0; j < descArr.length; ++j) {
  				desc += '<p class="mb-1">'+descArr[j]+'</p>';
  			}
  			var time = '';
  			if (items[i]['time'] != ''){
  				time = '<span class="c-time">'+items[i]['time']+' massage - </span>';
  			}
  			html += '<div class="col-sm-12 pb-3">'+
						'<p class="c-font-bold c-font-18" style="color: '+items[i]['color']+'">'+items[i]['name']+'</p>'+
						desc+
						'<p>'+time+'<span class="c-price">'+items[i]['price']+'</span></p>'+
					'</div>';
			$('.c-manicures-list').html(html);
  		}
  	}

}
