<!-- BEGIN: LAYOUT/HEADERS/HEADER-1 -->
<!-- BEGIN: HEADER -->
<header class="c-layout-header c-layout-header-4 c-layout-header-default-mobile" data-minimize-offset="80">
    <div class="c-navbar">
        <div class="container-fluid">
            <!-- BEGIN: BRAND -->
            <div class="c-navbar-wrapper clearfix">
                <div class="c-brand c-pull-left">
                    <a href="/" class="c-logo">
                        <img src="assets/base/img/layout/logos/logo-150.png" alt="Grand Nail Spa" class="c-desktop-logo">
                        <img src="assets/base/img/layout/logos/logo-150.png" alt="Grand Nail Spa" class="c-desktop-logo-inverse">
                        <img src="assets/base/img/layout/logos/logo-150.png" alt="Grand Nail Spa" class="c-mobile-logo"> 
                    </a>
                    <button class="c-hor-nav-toggler btn-menu" type="button" data-target=".c-mega-menu" (click)="showMegaMenu()">
                        <span class="c-line"></span>
                        <span class="c-line"></span>
                        <span class="c-line"></span>
                    </button>
                    <button class="c-topbar-toggler btn-menu" type="button" (click)="showExpandedMenu()">
                        <i class="fa fa-ellipsis-v"></i>
                    </button>
                    <button class="c-search-toggler btn-menu hide" type="button">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <!-- END: BRAND -->
                
                <!-- BEGIN: HOR NAV -->
                <!-- BEGIN: LAYOUT/HEADERS/MEGA-MENU -->
                <!-- BEGIN: MEGA MENU -->
                <!-- Dropdown menu toggle on mobile: c-toggler class can be applied to the link arrow or link itself depending on toggle mode -->
                <nav class="c-mega-menu c-pull-right c-mega-menu-dark c-mega-menu-dark-mobile c-theme c-fonts-uppercase c-fonts-bold">
                    <ul class="nav navbar-nav c-theme-nav">
                        <li class="c-menu-type-classic" [routerLinkActive]="['c-active']" [routerLinkActiveOptions]="{ exact: true }">
                            <a routerLink = "/home" class="c-link dropdown-toggle">Home</a>
                        </li>
                        <li class="c-menu-type-classic" [routerLinkActive]="['c-active']">
                            <a routerLink = "/services" class="c-link dropdown-toggle">Services
                            </a>
                        </li>
                        <li class="c-menu-type-classic" [routerLinkActive]="['c-active']">
                            <a routerLink = "/pedicure" class="c-link dropdown-toggle">Pedicure</a>
                        </li>

                        <li class="c-menu-type-classic" [routerLinkActive]="['c-active']">
                            <a routerLink = "/manicure" class="c-link dropdown-toggle">Manicure</a>
                        </li>
                        <li class="c-search-toggler-wrapper hide">
                            <a href="#" class="c-btn-icon c-search-toggler">
                                <i class="fa fa-search"></i>
                            </a>
                        </li>
                        
                        <li class="hide">
                            <a href="javascript:;" data-toggle="modal" data-target="#login-form" class="c-btn-border-opacity-04 c-btn btn-no-focus c-btn-header btn btn-sm c-btn-border-1x c-btn-white c-btn-circle c-btn-uppercase c-btn-sbold">
                                <i class="icon-user"></i> Sign In
                            </a>
                        </li>
                        
                    </ul>
                </nav>
                <!-- END: MEGA MENU -->
                <!-- END: LAYOUT/HEADERS/MEGA-MENU -->
                <!-- END: HOR NAV -->
            </div>
            <!-- BEGIN: LAYOUT/HEADERS/QUICK-CART -->
           
            <!-- END: LAYOUT/HEADERS/QUICK-CART -->
        </div>
    </div>
</header>
<!-- END: HEADER -->
<!-- END: LAYOUT/HEADERS/HEADER-1 -->


<!-- BEGIN: PAGE CONTAINER -->
<div class="c-layout-page">
<!-- BEGIN: PAGE CONTENT -->
  <router-outlet></router-outlet>
<!-- END: PAGE CONTENT -->
</div>
<!-- END: PAGE CONTAINER -->

<div class="c-layout-footer c-layout-footer-1">
    <div class="c-prefooter">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="c-logo">
                        <a href="#">
                            <img src="assets/base/img/layout/logos/logo-150.png" alt="Grand Nail Spa"> 
                            <h3 class="c-font-bold c-font-white c-font-uppercase hide">Grand Nail Spa</h3>
                        </a>
                    </div>
                    <div class="c-contact c-font-thin pt-3 pb-1 hide"> 
                        <span class="c-font-bold">Grand Nail Spa – Euless</span>
                        <br> Address: <a href="https://www.google.com/maps/place/2901+Rio+Grande+Blvd+%23200,+Euless,+TX+76039/@32.877969,-97.102675,17z/data=!3m1!4b1!4m5!3m4!1s0x864dd5562ef30c33:0xcc84d759766514ed!8m2!3d32.877969!4d-97.1004863" target="_blank">2901 Rio Grande Blvd, Suite 200, Euless, TX 76039</a>
                        <br> Phone: <a href="tel:817-358-5555" target="_blank">817-358-5555</a></div>
                    <div class="c-contact c-font-thin pt-3 pb-1 hide"> 
                        <span class="c-font-bold">Grand Nail Spa – Irving</span>
                        <br> Address: <a href="https://www.google.com/maps/place/6761+N+MacArthur+Blvd+%23120,+Irving,+TX+75039/@32.9015764,-96.9634079,17z/data=!3m1!4b1!4m5!3m4!1s0x864c2833787d3fef:0x176c488b7b9f5453!8m2!3d32.9015764!4d-96.9612192" target="_blank"> 6761 N MacArthur Blvd, Suite 120, Irving, TX 75039</a>
                        <br> Phone: <a href="tel:972-863-9350" target="_blank">972-863-9350</a></div>

                    <div class="pt-3 pb-1"> 
                        <p class="c-font-bold">Email</p>
                        <a href="mailto:info@grandnailspa.net" target="_blank">info@grandnailspa.net</a> 
                     </div>
                    
                    <div class="c-hours pt-3 pb-1 hide">
                        <p class="c-font-bold">Hours of Operation</p>
                        <dl class="row">
                            <dt class="col-sm-3">Mon - Fri:</dt>
                            <dd class="col-sm-9">9:30 Am - 7:30 PM</dd>
                            <dt class="col-sm-3">Sat:</dt>
                            <dd class="col-sm-9">9:00 AM - 7:00 PM</dd>
                            <dt class="col-sm-3">Sun:</dt>
                            <dd class="col-sm-9">11:30 AM - 6:00 PM</dd>
                        </dl>
                    </div>

                    <div class="c-last pt-3 pb-1 hide">
                        <p class="c-font-bold">Find us</p>
                        <div class="socicons">
                            <a href="https://www.facebook.com/grandnail.spaeuless.1" class="socicon-btn socicon-facebook tooltips" data-original-title="Facebook"></a>
                        </div>
                    </div>

                    <div class="c-links d-none hide">
                        <ul class="c-nav col-12">
                            <li>
                                <a href="javascript:;" routerLink = "/" class="c-font-thin">Home</a>
                            </li>
                            <li>
                                <a href="javascript:;" routerLink = "/services" class="c-font-thin">Services</a>
                            </li>
                            <li>
                                <a href="javascript:;" routerLink = "/pedicure" class="c-font-thin">Pedicure</a>
                            </li>
                            <li>
                                <a href="javascript:;" routerLink = "/manicure" class="c-font-thin">Manicure</a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div class="col-md-6" id="c-lienlac">
                    <div class="c-feedback">
                        <h3>Contact Us</h3>
                        <form  class="form-" method="post" target="_blank" [formGroup] = "contactForm" (ngSubmit) = "onContactFormSubmit()" *ngIf="!isSend">
                            <div class="c-form-wrap">
                                <div class="c-form-wrap-group">
                                    <input type="text" placeholder="Your Name" class="form-control" name="name" id="name" formControlName="name" >
                                    <input type="text" placeholder="Subject" class="form-control" name="subject" id="subject" formControlName="subject"> </div>
                                <div class="c-form-wrap-group">
                                    <input type="text" placeholder="Your Email" class="form-control" name="email" id="email" formControlName="email">
                                    <input type="text" placeholder="Contact Phone (optional)" class="form-control" name="phone" id="phone" formControlName="phone"> </div>
                            </div>
                            <textarea rows="8" name="message" placeholder="Write comment here ..." class="form-control" id="message" formControlName="message"></textarea>
                            <button type="submit" class="btn c-btn-dark c-btn-uppercase btn-lg c-btn-sbold btn-block c-btn-square" name="sendmail">Send it</button>
                        </form>
                        <div *ngIf="isSend">
                            <h4 class="c-font-20 c-font-white c-font-capitalize">{{sendStatus}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- BEGIN: CONTENT/COMING SOON -->
<div class="c-content-box c-contact-maps">
  <div class="contact-container">
    <div class="">
      <div class=" contact-maps">
        <div class="gmap-box" id="gmaps"></div>
      </div>
    </div>
  </div>
</div>
<!-- END: CONTENT/COMING SOON -->

<!-- BEGIN: LAYOUT/FOOTERS/FOOTER-1 -->
<a name="footer"></a>
<footer class="c-layout-footer c-layout-footer-1">
    <div class="c-postfooter">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="c-copyright c-font-14"> Copyright &copy; <span id="current-year"></span> Grand Nail Spa. All rights reserved.</p>
                    <p class="c-font-regular c-font-14">Developed by <a class="c-font-regular" href="http://www.dothang.net" target="_blank">DoThang</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- END: LAYOUT/FOOTERS/FOOTER-1 -->
<!-- BEGIN: LAYOUT/FOOTERS/GO2TOP -->
<div class="c-layout-go2top">
    <i class="icon-arrow-up"></i>
</div>
<!-- END: LAYOUT/FOOTERS/GO2TOP -->
<!-- BEGIN: LAYOUT/BASE/BOTTOM -->
