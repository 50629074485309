import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule  } from '@angular/http';
import { RouterModule, Routes} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { EmailService } from './_services/email.service';
import { ServicesService } from './_services/services.service';

import { HomeComponent } from './home/home.component';
import { ServicesComponent } from './services/services.component';
import { PedicureComponent } from './pedicure/pedicure.component';
import { ManicureComponent } from './manicure/manicure.component';

const appRoutes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'services', component: ServicesComponent},
  {path: 'services/:id', component: ServicesComponent},
  {path: 'pedicure', component: PedicureComponent},
  {path: 'manicure', component: ManicureComponent},
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    PedicureComponent,
    ManicureComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule,
    CarouselModule,
    CommonModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot (appRoutes, { useHash: true }),
    Ng2PageScrollModule,
    SlickCarouselModule,
    BrowserAnimationsModule
  ],
  providers: [
    EmailService,
    ServicesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
