<div class="c-top-bg" style="background-image: url('./assets/base/img/content/shop/euless2020-1.jpg');"></div>
<div class="c-content-box c-size-md c-bg-grey c-{{item['code']}}">
	<div class="container">
		<div class="row">
			<div class="col-md-4 col-sm-12">
				<ul class="c-content-list-1 c-theme c-separator-dot">
					<li *ngFor="let s of sers"><a [routerLink]="['/services', s.id]" >{{s['name']}}</a></li>
				</ul>
			</div>
			<div class="col-md-8 col-sm-12">
				<div class="c-content-title-1">
					<h3 class="c-center c-font-sbold mb-2">{{item['name']}}</h3>
					<div class="c-line-center c-theme-bg"></div>
				</div>
				<div class="c-item-list">
					<dl class="row" *ngFor="let l of list">
						<dt class="col-sm-6" style="color: {{ l['color'] }}">
							<span>{{ l['name'] }}</span>
						</dt>
                    	<dd class="col-sm-6 text-right">
                    		<span class="c-time">{{ l['time'] }} - </span><span class="c-price">{{ l['price'] }}</span>
                    	</dd>
                    </dl>
                </div>
				<div class="col-sm-12 c-item-intro-container my-3">
					<div class="c-font-center c-font-sbold c-font-14 c-item-intro"></div>
					<div class="c-font-center my-4">
						<img src="./assets/base/img/content/services/{{item['img']}}" class="c-item-img img-responsive">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="c-content-box c-size-md c-bg-grey">
	<div class="container">
		<owl-carousel-o [options]="customOptions">
		    <ng-container *ngFor="let slide of slides">
		      <ng-template carouselSlide>
		        <img src="./assets/base/img/content/shop/{{ slide.img }}.jpg" alt="" />
		      </ng-template>
		    </ng-container>
		</owl-carousel-o>
	</div>
</div>