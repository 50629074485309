import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
// import { OwlCarousel } from 'ngx-owl-carousel';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { ServicesService } from '../_services/services.service';

declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
	sId = '';
	sers = this.services['services'];
	item = {};
	list = [];

	slides = [
		{img: "euless0"},
		{img: "euless1"},
		{img: "euless2"},
		{img: "euless4"},
		{img: "euless5"},
		{img: "euless6"},
		{img: "euless7"},
	];
	customOptions: OwlOptions = {
	    loop: true,
	    mouseDrag: false,
	    touchDrag: false,
	    pullDrag: false,
	    dots: false,
	    navSpeed: 700,
	    autoplaySpeed: 500,
	    autoplay: true,
	    navText: ['<', '>'],
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 2
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 3
	      }
	    },
	    nav: true
	}

  	constructor(
  		private route: ActivatedRoute,
  		private services: ServicesService
  	) {
  		if ($('.c-mega-menu').hasClass('c-shown')){
            $('.c-mega-menu').removeClass('c-shown');
            $('body').removeClass('c-mega-menu-shown');
        }
  	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.sId = params['id'];
			if (this.sId == undefined) this.sId = '1';
			this.getServiceById(this.sId);
		});
	}

	getServiceById(id){
		var temp = this.sers.filter(ft => ft['id'] == id);
		if (temp.length > 0){
			this.item = temp[0];
			this.renderIntro(this.item);
			this.list = this.services[this.item['code']];
		}
	}

	renderList(list){
		var html = '';
		for (var i = 0; i < list.length; ++i) {
			html += '<dl class="row pb-2">'+
						'<dt class="col-sm-6" style="color: '+list[i]['color']+'">'+list[i]['name']+'</dt>'+
                    	'<dd class="col-sm-6 text-right">'+list[i]['time']+' - '+list[i]['price']+'</dd>'+
                    '</dl>';
		}
		$('.c-item-list').html(html);
	}

	renderIntro(item){
		var intro = item['desc'].split('\n');
		var html = '';
		for (var i = 0; i < intro.length; ++i) {
			html += '<p class="mb-1">'+intro[i].trim()+'</p>'
		}
		$('.c-item-intro').html(html);
	}

}
