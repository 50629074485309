import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor() { }

  services = [
  	{
  		id: 1,
  		name: 'Pedicure',
  		code: 'pedicure',
  		desc: '* All specialty services also include HOT STONE MASSAGE and PARAFFIN TREATMENT  \n * HOT STONE MASSAGE * \n (For relief of stress and negative energy) \n Massage using volcanic rocks that have a natural ability to ionize your body for better blood flow and include an over all well-being. \n * PARAFFIN TREATMENT * \n (For dry skin relief) \n A specialized type of wax that is formulated to infuse moisture in your skin for a complete feeling of softness and comfort \n * All specialty manicure services include a COMPLIMENTARY shoulder and neck massage',
		img: '105.jpg'  		
  	},
  	{
  		id: 2,
  		name: 'Manicure',
  		code: 'manicure',
  		desc : '* All specialty services also include HOT STONE MASSAGE and PARAFFIN TREATMENT \n * HOT STONE MASSAGE * \n (For relief of stress and negative energy) \n Massage using volcanic rocks that have a natural ability to ionize your body for better blood flow and include an over all well-being. \n * PARAFFIN TREATMENT * \n (For dry skin relief) \n A specialized type of wax that is formulated to infuse moisture in your skin for a complete feeling of softness and comfort \n * All specialty manicure services include a COMPLIMENTARY shoulder and neck massage',
  		img: '100.jpg'
  	},
  	{
  		id: 3,
  		name: 'Shellac Gel Manicure',
  		code: 'shellac',
  		desc: '',
  		img: '107.jpg'
  	},
  	{
  		id: 4,
  		name: 'Nexgen Nails',
  		code: 'nexgen',
  		desc: '',
  		img: '108.jpg'
  	},
  	{
  		id: 5,
  		name: 'Polish Change',
  		code: 'polish',
  		desc: '',
  		img: '109.jpg'
  	},
  	{
  		id: 6,
  		name: 'Solar Nails',
  		code: 'solar',
  		desc: '',
  		img: '110.jpg'
  	},
  	{
  		id: 7,
  		name: 'Acrylic',
  		code: 'acrylic',
  		desc: '',
  		img: '111.jpg'
  	},
  	{
  		id: 8,
  		name: 'Kid\'s Menu',
  		code: 'kid',
  		desc: '',
  		img: '112.jpg'
  	},
  	{
  		id: 9,
  		name: 'Reflexology',
  		code: 'reflexolofy',
  		desc: '100% Sensitization & Sterilization \n * we reserved the right to refuse service to anyone with reasonable cause. \n * Furthermore, prices are subject to change without prior notice.',
  		img: '113.jpg'
  	},
  	{
  		id: 10,
  		name: 'Waxing',
  		code: 'waxing',
  		desc: '',
  		img: '114.jpg'
  	},
  	{
  		id: 11,
  		name: 'Tinting',
  		code: 'tinting',
  		desc: 'Tools Sanitized by Medical Grade UltraClave Auto Steam Sterilizer',
  		img: '115.jpg'
  	}
  ]

  tinting = [
  	{
  		name: 'Eyebrow',
  		price: '$17',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Eyelash',
  		price: '$20',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  waxing = [
  	{
  		name: 'Eyebrows',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Lips',
  		price: '$7',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Chin',
  		price: '$8 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Sideburns',
  		price: '$15 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Full Face',
  		price: '$35 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Underarms',
  		price: '$20 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Half Arm',
  		price: '$22 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Full Arm',
  		price: '$40 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Half Leg',
  		price: '$30 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Full  Leg',
  		price: '$48 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Bikini',
  		price: '$30 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Brazillian',
  		price: '$50 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Half Back',
  		price: '$30 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Full Back',
  		price: '$50 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Stomach',
  		price: '$20 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Chest',
  		price: '$25 & Up',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  reflexolofy = [
  	{
  		name: 'Herbal Therapy',
  		price: '$35',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: '15 Minutes',
  		price: '$15',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: '10 Minutes',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  kid = [
  	{
  		name: 'Pedicure',
  		price: '$20',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Manicure',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Hand Polish Change',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Hand Polish French',
  		price: '$7',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Toe Polish Change',
  		price: '$7',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Toe Polish French',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Designs',
  		price: '$3',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Kids’ Grand Pedicure',
  		price: '$27',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  acrylic = [
  	{
  		name: 'Full Set',
  		price: '$30',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Fill',
  		price: '$20',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'French',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Acrylic Removal with Service',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Acrylic Removal without Service',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  solar = [
  	{
  		name: 'Solar Pink & White Full Set',
  		price: '$45',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Solar Pink & White Fill',
  		price: '$38',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Solar Pink Fill',
  		price: '$25',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'UV Shiny Gel',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Nail Removal with Service',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Nail Removal without Service',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  polish = [
  	{
  		name: 'Hands',
  		price: '$8',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'French',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Feet',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'French',
  		price: '$15',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  nexgen = [
  	{
  		name: 'Color',
  		price: '$35',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'French',
  		price: '$40',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Nexgen Removal with Service',
  		price: '$5',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Nexgen Removal without Service',
  		price: '$10',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  shellac = [
  	{
  		name: 'Classic Gel Manicure',
  		price: '$35',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'French Gel Manicure',
  		price: '$40',
  		time: '',
  		desc: '',
  		color: ''
  	},
  	{
  		name: 'Shellac Gel for every manicure and pedicure',
  		price: 'add $20',
  		time: '',
  		desc: '',
  		color: ''
  	}
  ]

  manicure = [
  	{
  		name: '** ORGANIC DREAM **',
  		price: '$35',
  		time: '17 mins',
  		desc: '* The All-Inclusive * \n Your choice of GREEN TEA – LEMONGRASS or MANDARIN – MANGO \n Our Organic Dream pedicure includes all special organic aromatherapy, sugar scrubs exfoliating mask, lotion, and stone massage. Your nail care also consists of the cuticle, callus removal, paraffin wax, relaxing hot towel wrap. Enjoy the massage with our special organic massage oil and your choice of polish.',
  		color: 'lime'
  	},
  	{
  		name: '* HEAVENLY DREAM *',
  		price: '$30',
  		time: '15 mins',
  		desc: 'Aromatic foot soak, hot towel wrap, aromatic dry skin exfoliation scrub, aromatic mud mask, intensive callus and heel treatment, natural nail care, volcanic hot stone message and paraffin wax treatment, extended aromatic hydrating reflexology message.',
  		color: 'blue'
  	},
  	{
  		name: '* MILK & HONEY *',
  		price: '$25',
  		time: '12 mins',
  		desc: 'The alluring union of real Milk essence and Honey extracts to melt away your stressful day.',
  		color: 'yello'
  	},
  	{
  		name: '* SUNKIST PLEASURES *',
  		price: '$25',
  		time: '12 mins',
  		desc: 'The tantalizing scent of citrus with its natural exfoliating properties, and the addition of fresh orange slice incorporated into your services.',
  		color: 'orange'
  	},
  	{
  		name: '* CUCUMBER INDULGENCE *',
  		price: '$25',
  		time: '12 mins',
  		desc: 'The natural qualities of Cucumber essence to rejuvenate your skin’s healthy color and glow.',
  		color: 'green'
  	},
  	{
  		name: '* TROPICAL PARADISE *',
  		price: '$25',
  		time: '12 mins',
  		desc: 'The Island get-a-way service that includes fresh flowers and tropical lotions and exfoliates.',
  		color: 'red'
  	},
  	{
  		name: 'LAVENDER MINT GARDEN',
  		price: '$23',
  		time: '10 mins',
  		desc: 'The soothing aroma of Lavender and the refreshing aroma of Mint to awaken your senses.',
  		color: 'pink'
  	},
  	{
  		name: 'THE GRAND DELUXE',
  		price: '$23',
  		time: '10 mins',
  		desc: 'Because You Deserve It! \n The service includes your choice between Hot Stone Massage or Paraffin Treatment, Hot Towel Wrap, Dry skin exfoliation Natural nail care, Extended massaging. Your choice polish. \n * Aroma therapy can be added * (At additional cost)',
  		color: 'black'
  	},
  	{
  		name: 'THE CLASSIC',
  		price: '$16',
  		time: '7 mins',
  		desc: 'The gold standing of Manicures & Pedicures \n This service truly defines Grand Nail Spa, although considered to be our Basic Manicure & Pedicure. \n This service is still considered deluxe by all standards',
  		color: 'brown'
  	}
  ]

  pedicure = [
  	{
  		name: '** ORGANIC DREAM **',
  		price: '$60',
  		time: '27 mins',
  		desc: '* The All-Inclusive * \n Your choice of GREEN TEA – LEMONGRASS or MANDARIN – MANGO \n Our Organic Dream pedicure includes all special organic aromatherapy, sugar scrubs exfoliating mask, lotion, and stone massage. Your nail care also consists of the cuticle, callus removal, paraffin wax, relaxing hot towel wrap. Enjoy the massage with our special organic massage oil and your choice of polish.',
  		color: 'lime'
  	},
  	{
  		name: '* HEAVENLY DREAM *',
  		price: '$55',
  		time: '25 mins',
  		desc: 'Aromatic foot soak, hot towel wrap, aromatic dry skin exfoliation scrub, aromatic mud mask, intensive callus and heel treatment, natural nail care, volcanic hot stone message and paraffin wax treatment, extended aromatic hydrating reflexology message.',
  		color: 'blue'
  	},
  	{
  		name: '* MILK & HONEY *',
  		price: '$50',
  		time: '21 mins',
  		desc: 'The alluring union of real Milk essence and Honey extracts to melt away your stressful day.',
  		color: 'yello'
  	},
  	{
  		name: '* SUNKIST PLEASURES *',
  		price: '$50',
  		time: '21 mins',
  		desc: 'The tantalizing scent of citrus with its natural exfoliating properties, and the addition of fresh orange slice incorporated into your services.',
  		color: 'orange'
  	},
  	{
  		name: '* CUCUMBER INDULGENCE *',
  		price: '$50',
  		time: '21 mins',
  		desc: 'The natural qualities of Cucumber essence to rejuvenate your skin’s healthy color and glow.',
  		color: 'green'
  	},
  	{
  		name: '* TROPICAL PARADISE *',
  		price: '$50',
  		time: '21 mins',
  		desc: 'The Island get-a-way service that includes fresh flowers and tropical lotions and exfoliates.',
  		color: 'red'
  	},
  	{
  		name: 'LAVENDER MINT GARDEN',
  		price: '$45',
  		time: '18 mins',
  		desc: 'The soothing aroma of Lavender and the refreshing aroma of Mint to awaken your senses.',
  		color: 'pink'
  	},
  	{
  		name: 'THE GRAND DELUXE',
  		price: '$38',
  		time: '15 mins',
  		desc: 'Because You Deserve It! \n The service includes your choice between Hot Stone Massage or Paraffin Treatment, Hot Towel Wrap, Dry skin exfoliation Natural nail care, Extended massaging. Your choice polish. \n * Aroma therapy can be added * (At additional cost)',
  		color: 'black'
  	},
  	{
  		name: 'THE CLASSIC',
  		price: '$28',
  		time: '10 mins',
  		desc: 'The gold standing of Manicures & Pedicures \n This service truly defines Grand Nail Spa, although considered to be our Basic Manicure & Pedicure. \n This service is still considered deluxe by all standards',
  		color: 'brown'
  	}
  ]
  
}
