import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  	constructor() { 
  		if ($('.c-mega-menu').hasClass('c-shown')){
            $('.c-mega-menu').removeClass('c-shown');
            $('body').removeClass('c-mega-menu-shown');
        }
    }

  ngOnInit(): void {
	$.getScript('../../../assets/base/js/components.js');
	$.getScript('../../../assets/base/js/app.js');
	$.getScript('../../../assets/base/js/slider-4.js');
  }

}
