import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Resolve } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

export interface IMessage {
  name?: string,
  email?: string,
  message?: string,
  subject?: string,
  phone?: string
}

@Injectable()
export class EmailService {

  private emailUrl = 'http://nsemail.nghiasinh.org/sendemail.php';

  constructor(private http: Http) {

  }

  sendEmail(email, name, subject, message, phone){
        return this.http.get(this.emailUrl+"?email="+ email+'&name='+name+'&subject='+subject+'&message='+message+'&phone='+phone);
  }

}