import { Component } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { Resolve } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { EmailService, IMessage } from './_services/email.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	imessage: IMessage = {};

	contactForm = this.formBuilder.group({
		name: '',
		subject: '',
		email: '',
		phone: '',
		message: '',
	})
	isSend = false;
	sendStatus = '';

  	searchForm = this.formBuilder.group({
	    searchkey: [''],
	});

  	constructor(
		private formBuilder:FormBuilder,
  		private _email: EmailService,
	){
  	}

  	ngAfterContentInit(){
	   	$.getScript('../../../assets/base/js/components.js');
		$.getScript('../../../assets/base/js/app.js');
		$.getScript('../../../assets/base/js/slider-4.js');
		$.getScript('../../../assets/plugins/gmaps/gmaps.js');
		$.getScript('../../../assets/base/js/contact.js');
  	}

  	showMegaMenu(){
		// console.log('app');
		if ($('body').hasClass('c-mega-menu-shown')){
			$('body').removeClass('c-mega-menu-shown');
			$('.c-mega-menu').removeClass('c-shown');
		}else{
			$('.c-layout-header-fixed').addClass('c-mega-menu-shown');
			$('.c-mega-menu').addClass('c-shown');
		}
	}

	showExpandedMenu(){
		if ($('body').hasClass('c-topbar-expanded')){
			$('body').removeClass('c-topbar-expanded');
		}else{
			$('body').addClass('c-topbar-expanded');
		}
	}

	// BEGIN: SEND MAIL
  	isValidName = false;
  	isValidEmail = false;
  	isValidSubject = false;
  	isValidPhone = false;
  	isValidMessage = false;
  	onContactFormSubmit(){
  		let fromname = this.contactForm.controls['name'].value;
  		let fromemail = this.contactForm.controls['email'].value;
  		let fromsubject = this.contactForm.controls['subject'].value;
  		let fromphone = this.contactForm.controls['phone'].value;
  		let frommessage = this.contactForm.controls['message'].value;
  		if (fromname == "" || fromname == null){
	    	this.isValidName = true;
	    	return;
	  	} else {
			this.imessage.name = fromname;
			// let name: IMessage = fromname;
			this.isValidName = false;
      	}

      	if (fromemail == "" || fromemail == null){
	    	this.isValidEmail = true;
	    	return;
	  	} else {
			// let email: IMessage = fromemail;
			this.imessage.email = fromemail;
			this.isValidEmail = false;
      	}
      	
      	if(fromsubject == "" || fromsubject == null){
	    	this.isValidSubject = true;
	    	return;
	  	} else {
			// let subject: IMessage = fromsubject;
			this.imessage.subject = fromsubject;
			this.isValidSubject = false;
      	}
      	
      	if(fromphone == "" || fromphone == null){
	    	// this.isValidPhone = true;
	    	// return;
	    	this.imessage.phone = '';
	  	} else {
			// let phone: IMessage = fromphone;
			this.imessage.phone = fromphone;
			this.isValidPhone = false;
      	}

      	if(frommessage == "" || frommessage == null){
	    	this.isValidMessage = true;
	    	return;
	  	} else {
			// let message: IMessage = frommessage;
			this.imessage.message = frommessage;
			this.isValidMessage = false;
      	}

      	this.sendEmail(this.imessage.email, this.imessage.name, this.imessage.subject, this.imessage.message, this.imessage.phone);
  	}

	sendEmail(email, name, subject, message, phone) {
	 	this._email.sendEmail(email, name, subject, message, phone)
	      .map((response: Response) => response.json())
	      .subscribe(data => {
	        // set items to json response
	        let status = data['status'];
	        if (data['status'] == 'success'){
	        	this.isSend = true;
	        	this.sendStatus = 'Your message has been sent successfully';
	        }else{
	        	this.isSend = false;
	        	this.sendStatus = 'Your message has not been sent';

	        }
	        // console.log(data);
	  	});
	}
  	// END: SEND MAIL
}
